import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import( '../pages/login/login.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import( '../pages/layout/layout.vue'),
    children: [
      {
        path: '/workbench',
        name: 'workbench',
        component: () => import( '../pages/workbench/workbench.vue'),
        meta:{
          keepAlive:false,
          title:'工作台'
        }
      },
      {
        path: '/operatingDay',
        name: 'operatingDay',
        component: () => import( '../pages/operatingDay/operatingDay.vue'),
        meta:{
          keepAlive:false,
          title:'运行日对比'
        }
      },
      {
        path: '/stock',
        name: 'stock',
        component: () => import( '../pages/stock/stock.vue'),
        meta:{
          keepAlive:false,
          title:'现货价格分析'
        }
      },
      {
        path: '/transactionDay',
        name: 'transactionDay',
        component: () => import( '../pages/transactionDay/transactionDay.vue'),
        meta:{
          keepAlive:false,
          title:'日前交易分析'
        }
      },
      {
        path: '/electricitySale',
        name: 'electricitySale',
        component: () => import( '../pages/electricitySale/electricitySale.vue'),
        meta:{
          keepAlive:false,
          title:'售电持仓看板'
        }
      },
      {
        path: '/simulation',
        name: 'simulation',
        component: () => import( '../pages/simulation/simulation.vue'),
        meta:{
          keepAlive:false,
          title:'中长期模拟交易'
        }
      },
      {
        path: '/curve',
        name: 'curve',
        component: () => import( '../pages/curve/curve.vue'),
        meta:{
          keepAlive:false,
          title:'曲线分解方式'
        }
      },
      {
        path: '/evaluation',
        name: 'evaluation',
        component: () => import( '../pages/evaluation/evaluation.vue'),
        meta:{
          keepAlive:false,
          title:'合约估价'
        }
      },
      {
        path: '/decompose',
        name: 'decompose',
        component: () => import( '../pages/decompose/decompose.vue'),
        meta:{
          keepAlive:false,
          title:'电量分解对比'
        }
      },
      
    ]
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
