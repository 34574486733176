import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "../src/assets/iconfont/iconfont.css"
import * as echarts from 'echarts'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import moment from 'moment'

const project= createApp(App)

project.config.globalProperties.$moment=moment
project.config.globalProperties.$echarts=echarts

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    project.component(key, component)
}


  project.use(store).use(router).use(ElementPlus,{locale}).mount('#app')

